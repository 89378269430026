@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

body,
html {
  font-family: "Inter", sans-serif;
}
p {
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  line-height: 185%;
}

a {
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
  color: #6c5ce7;
  text-decoration: none;
}

/* common properties for app component content*/

.app-component-content {
  padding: 20px;
  text-align: center;
}

.app-component-content > p {
  padding: 20px;
}

/* asset sidebar styles */

.asset-sidebar {
  box-sizing: border-box;
  display: flex;
  width: auto;
  height: 550px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #a9b6cb;
  text-align: center;
  margin: 1rem;
  border-radius: 8px;
}

.asset-sidebar-icon {
  display: flex;
  justify-content: center;
}

.asset-sidebar-icon > img {
  width: 52px;
  height: 52px;
}

.app-component-content > h4 {
  font-family: "Inter";
  font-style: normal;
  font-weight: 800;
  font-size: 22px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.00019em;
  color: #222222;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}

/* app configuration extension styles  */

.app-config {
  box-sizing: border-box;
  display: flex;
  width: auto;
  height: 575px;
  justify-content: center;
  align-items: center;
  border: 1px dashed #a9b6cb;
  text-align: center;
  margin: 15px;
  border-radius: 8px;
}

.app-config-icon {
  display: flex;
  justify-content: center;
}

.app-config-icon > img {
  width: 52px;
  height: 52px;
}

/* custom field styles */

.custom-field {
  box-sizing: border-box;
  display: flex;
  height: 260px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px dashed #a9b6cb;
  border-radius: 8px;
}

.custom-field-container {
  height: 218px;
}

.custom-field-content {
  padding: 20px 0;
}

.custom-field-icon {
  display: flex;
  justify-content: center;
}

.custom-field-icon > img {
  width: 52px;
  height: 52px;
}

/* dashboard styles */

.dashboard {
  box-sizing: border-box;
  display: flex;
  width: auto;
  height: 320px;
  justify-content: center;
  align-items: center;
}

.dashboard-container {
  padding: 8px 0px 8px 0px;
}

.dashboard-icon {
  display: flex;
  justify-content: center;
}

/* entry sidebar styles */

.entry-sidebar {
  box-sizing: border-box;
  display: flex;
  width: auto;
  height: 533px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px dashed #a9b6cb;
  border-radius: 8px;
}

.entry-sidebar-content {
  padding: 20px 0;
}

.entry-sidebar-icon {
  display: flex;
  justify-content: center;
}

.entry-sidebar-icon > img {
  width: 52px;
  height: 52px;
}

/* JSONRte Extension */

.jsonrte-extension {
  padding: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.jsonrte-extension > label {
  font-weight: 700;
}

.jsonrte-extension > p {
  padding: 0.25rem;
  color: #000000;
  border-bottom-right-radius: 0.375rem;
  border-bottom-left-radius: 0.375rem;
  border-bottom-width: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
}

/* app failed component */

.app-failed-container {
  padding: 5rem;
  max-width: 72rem;
  margin-left: auto;
  margin-right: auto;
}

.app-failed-container > .app-component-container {
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #fdf2c7;
  border-radius: 0.5rem;
}

.app-icon {
  margin-right: 0.5rem;
  color: #b36b34;
  width: 1.25rem;
  height: 1.25rem;
}

.app-content {
  display: flex;
  align-items: center;
}

.app-content > h3 {
  color: #b36b34;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
}

.app-text {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  color: #b36b34;
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.secondary-app-container {
  display: flex;
}

.secondary-app-container > button {
  display: inline-flex;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  margin-right: 0.5rem;
  background-color: #b36b34;
  color: #ffffff;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 500;
  text-align: center;
  align-items: center;
  border-radius: 0.5rem;
}

.app-learn-more {
  margin-right: 0.5rem;
  margin-left: -0.125rem;
  width: 1rem;
  height: 1rem;
}

.ui-location {
  list-style: circle;
  padding: 0 40px;
  margin-top: -20px;
}

.ui-location li {
  text-align: left;
}

/* Solo Stove BC Regional Products */
@import url("./regional-products.css");