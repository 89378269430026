.regional-product-field-heading {
  /* display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  z-index: 99;
  width: 100%;
  padding: 1rem 0; */
}

.regional-product-field-heading h4 {
  font-size: 14px;
  line-height: 1;
  margin: 0;
}
.regional-product-field-heading > img {
  width: 1rem;
  height: auto;
  margin-right: .5em;
}
.regional-product-field .app-component-content {
  padding-top: 0;
}

.Toastify h3 {
  font-size: 1.1em;
  margin: 0 0 .3em;
  line-height: 1;
}
.Toastify p {
  text-align: left;
  line-height: 1;
}